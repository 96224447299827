<template>
  <div>
    <div class="row">
      <div class="col-12 col-sm-6 text-center">
        <i class="fa fa-cash-register" />
        {{
          caixaControle && caixaControle.id && !caixaControle.dt_fechamento
            ? "Aberto"
            : "Fechado"
        }}
        <br />
        <div
          v-if="
            caixaControle && caixaControle.id && !caixaControle.dt_fechamento
          "
        >
          {{ caixaControle.vl_abertura | currencyWithMask }}
        </div>
        <div
          v-if="
            caixaControle && caixaControle.id && caixaControle.dt_fechamento
          "
        >
          <b-icon-clock /> {{ caixaControle.dt_fechamento | moment("HH:mm:ss")
          }}<br />
          <b-icon-person-badge /> {{ caixaControle.usuarioFechou }}
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div v-if="caixaControle && caixaControle.id">
          <div v-if="caixaControle.dt_fechamento">
            <i class="fa fa-cash-register" />
            Aberto ás <br />
          </div>
          <b-icon-clock /> {{ caixaControle.dt_abertura | moment("HH:mm:ss")
          }}<br />
          <b-icon-person-badge /> {{ caixaControle.usuarioAbriu }}
        </div>
      </div>
      <div class="col-12 pl-0 pr-0 pt-1">
        <b-button
          variant="primary"
          size="sm"
          v-if="
            ((caixaControle &&
              caixaControle.id &&
              caixaControle.dt_fechamento &&
              user.isAdmin) ||
              (!caixaControle && eHoje) ||
              (caixaControle &&
                caixaControle.id &&
                caixaControle.dt_fechamento &&
                caixaDehoje)) &&
            empresa
          "
          @click="abrirCaixa"
          ><b-icon-eject /> Abrir Caixa</b-button
        >
        <span v-if="caixaControle && caixaControle.id">
          <b-button
            variant="primary"
            size="sm"
            @click="fecharCaixa"
          >
            <span v-if="!caixaControle.dt_fechamento">
              <i class="fa fa-check" /> Fechar Caixa
            </span>
            <span v-else> <i class="far fa-eye" /> Fechamento</span>
          </b-button>
        </span>
        <b-btn
          variant="primary"
          size="sm"
          v-if="user && user.perfil == 'admin'"
          @click="abrirAcertos"
          ><b-icon-toggles2 /> Acertos</b-btn
        >

        <b-dropdown
          id="dropdown-1"
          text="Relatórios"
          variant="primary"
          size="sm"
        >
          <template #button-content> <b-icon-list /> Relatórios </template>
          <!-- <b-dropdown-item @click="openRelatorios('Fechamento')"
              ><i class="fa fa-cash-register" /> Fechamento de
              Caixa</b-dropdown-item
            > -->
          <b-dropdown-item @click="openRelatorios('Lançamentos')"
            ><i class="fa fa-list" /> Lançamentos</b-dropdown-item
          >
          <b-dropdown-item @click="openRelatorios('Guias Médicas')"
            ><i class="fa fa-user-md" /> Guias Médicas</b-dropdown-item
          >
          <b-dropdown-item @click="openRelatorios('Notas Fiscais', 'xl')"
            ><i class="fa fa-file-invoice-dollar" /> Notas
            Fiscais</b-dropdown-item
          >
          <b-dropdown-item @click="openRelatorios('PIX', 'xl')"
            ><i class="fa fa-qrcode" /> PIX</b-dropdown-item
          >
          <b-dropdown-item @click="openFindVenda()"
            ><i class="fas fa-external-link-alt"></i> Localizar Venda
            (cód)</b-dropdown-item
          >
          <!-- <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item active>Active action</b-dropdown-item>
          <b-dropdown-item disabled>Disabled action</b-dropdown-item> -->
        </b-dropdown>
        <b-btn
          :disabled="loading"
          @click="buscarCaixa"
          size="sm"
          variant="secondary"
        >
          <i
            v-if="!loading"
            class="fas fa-sync-alt"
          ></i>
          <b-spinner
            small
            v-if="loading"
          ></b-spinner>
          Atualizar
        </b-btn>
      </div>
    </div>
    <b-modal
      id="modal-open-caixa"
      title="Abrir Caixa"
      hide-footer
    >
      <open-caixa-form
        :empresa_id="empresa?empresa.id:0"
        :numCaixa.sync="numCaixa"
        :valorAbertura.sync="valorAbertura"
        @cancelarAbertura="cancelarAbertura"
        @salvarAbertura="salvarAbertura"
      />
    </b-modal>
    <b-modal
      id="modal-relatorios"
      hide-footer
      :title="'Relatório de ' + relatorio"
      :size="size"
      no-close-on-esc
      no-close-on-backdrop
    >
      <relatorio-fechamento
        @close="closeRelatorios"
        v-if="relatorio == 'Fechamento'"
      />
      <relatorio-guias-medicas
        v-if="relatorio == 'Guias Médicas'"
        @close="closeRelatorios"
      />
      <relatorio-lancamento
        @close="closeRelatorios"
        v-if="relatorio == 'Lançamentos'"
        :caixaControle="caixaControle"
      />
      <relatorio-notas-fiscais
        :clinica="empresa.id"
        @close="closeRelatorios"
        v-if="relatorio == 'Notas Fiscais'"
      />
      <relatorio-pix
        :clinica="empresa.id"
        @close="closeRelatorios"
        v-if="relatorio == 'PIX'"
      />
    </b-modal>
    <b-modal
      id="modal-fechar-caixa"
      hide-footer
      title="Fechamento de Caixa"
      size="lg"
    >
      <caixa-fechamento :caixaControle="caixaControle" />
    </b-modal>
    <b-modal
      id="modal-acertos"
      size="lg"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      no-enforce-focus
    >
      <template #modal-title> <b-icon-toggles2 /> Acertos Médicos</template>
      <acertos
        :data="data"
        :empresa="empresa"
        @close="
          $bvModal.hide('modal-acertos');
          carregar();
        "
      />
    </b-modal>
    <b-modal
      id="modal-venda"
      hide-footer
      title="Localizar Venda"
      size="lg"
    >
      <find-venda />
    </b-modal>
  </div>
</template>

<script>
import CaixaLib from "../../libs/CaixasLib.js";
import moment from "moment";
import RelatorioFechamento from "./RelatoriosForm/RelatorioFechamentoForm.vue";
import RelatorioLancamento from "./RelatoriosForm/RelatorioLancamentoForm.vue";
import RelatorioPix from "./RelatoriosForm/RelatorioPixForm.vue";
import RelatorioGuiasMedicas from "./RelatoriosForm/RelatorioGuiasMedicasForm.vue";
import CaixaFechamento from "../../components/Caixas/CaixaFechamento.vue";
import RelatorioNotasFiscais from "./Relatorios/RelatorioNotasFiscais.vue";
import OpenCaixaForm from "./OpenCaixaForm.vue";
import Acertos from "./Acertos.vue";
import FindVenda from "./Vendas/FindVenda.vue";

export default {
  components: {
    RelatorioFechamento,
    CaixaFechamento,
    RelatorioLancamento,
    RelatorioGuiasMedicas,
    RelatorioNotasFiscais,
    OpenCaixaForm,
    RelatorioPix,
    Acertos,
    FindVenda,
  },
  props: {
    empresa: Object,
    data: { type: String },
    numCaixa: Number,
  },
  mounted() {
    this.buscarCaixa();
  },
  data() {
    return {
      caixaNumero: this.numCaixa,
      valorAbertura: 0,
      caixaControle: {
        id: null,
        dt_fechamento: null,
        dt_abertura: null,
        vl_abertura: 0,
        usuarioAbriu: "",
        usuarioFechou: "",
      },
      relatorio: "",
      size: "",
      loading: false,
      eHoje: moment().isAfter(moment(moment().format("YYYY-MM-DD 00:00:00"))),
    };
  },
  watch: {
    data: function () {
      this.buscarCaixa();
    },
    empresa: function () {
      this.buscarCaixa();
    },
    numCaixa: function () {
      this.buscarCaixa();
    },
  },
  computed: {
    caixaDehoje() {
      return this.caixaControle
        ? moment(this.caixaControle.dt_abertura).isAfter(
            moment(moment().format("YYYY-MM-DD 00:00:00"))
          )
        : false;
    },
  },
  methods: {
    openFindVenda() {
      this.$bvModal.show("modal-venda");
    },
    abrirAcertos() {
      this.$bvModal.show("modal-acertos");
    },
    openRelatorios(relatorio, size) {
      this.relatorio = relatorio;
      this.size = size ? size : "md";
      this.$bvModal.show("modal-relatorios");
    },
    closeRelatorios() {
      this.relatorio = null;
      this.$bvModal.hide("modal-relatorios");
    },
    async buscarCaixa() {
      this.loading = true;
      if (this.empresa) {
        let result = await CaixaLib.buscarCaixaControle(
          this.empresa ? this.empresa.id : 0,
          moment(this.data, "DD/MM/YYYY").format("YYYY-MM-DD"),
          this.numCaixa
        );
        if (result && result.length > 0) {
          this.caixaControle = result[result.length - 1];
        } else {
          this.caixaControle = null;
        }
        this.$emit("caixaControle", this.caixaControle);
      }
      this.loading = false;
    },
    fecharCaixa() {
      this.$bvModal.show("modal-fechar-caixa");
      // this.$swal({
      //   title: "Tem certeza?",
      //   text: "Deseja mesmo fechar o caixa agora?",
      //   icon: "question",
      //   showCancelButton: true,
      //   cancelButtonText: "Não",
      //   confirmButtonText: "Sim",
      // }).then((res) => {
      //   if (res.isConfirmed) {
      //     this.salvarAbertura(null, false);
      //   }
      // });
    },
    abrirCaixa() {
      this.caixaNumero = 1;
      this.valorAbertura = 0;
      this.$bvModal.show("modal-open-caixa");
    },
    cancelarAbertura() {
      this.buscarCaixa();
      this.$bvModal.hide("modal-open-caixa");
    },
    async salvarAbertura(e, abertura = true) {
      if (e) {
        e.preventDefault();
      }

      let obj = {
        caixa_num: this.numCaixa,
        empresa_id: this.empresa ? this.empresa.id : 0,
        dt_caixa: moment(this.data, "DD/MM/YYYY").format("YYYY-MM-DD "),
      };
      if (abertura) {
        obj.vl_abertura = this.formatValorReal(this.valorAbertura);
        obj.user_abertura_id = this.$store.state.auth.user.id;
      } else {
        // obj.vl_fechamento = this.formatValorReal(this.valorAbertura);
        obj.user_fechamento_id = this.$store.state.auth.user.id;
        obj.id = this.caixaControle.id;
      }
      obj[abertura ? "dt_abertura" : "dt_fechamento"] =
        moment(this.data, "DD/MM/YYYY").format("YYYY-MM-DD ") +
        moment().format("HH:mm:ss");

      let result = await CaixaLib.registraControle(obj);
      this.$swal({
        title: result
          ? "Caixa " + (abertura ? "aberto" : "fechado") + " com sucesso!"
          : "Erro ao realizar a transação de caixa",
        toast: true,
        position: "top-end",
        timer: 2200,
        icon: result ? "success" : "error",
        showConfirmButton: false,
      });

      if (result) {
        this.cancelarAbertura();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
